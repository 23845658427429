<template>
    <el-row justify="center">
        <el-col :span="18" :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
            <div class="yemianzhuti">
                <div class="nevBtn">
                    <div class="nevBtnhang" style="padding-top: 1rem;">
                        <div class="nevBtnitem" @click="shoyerkBtncaozuo('AIinterview')">
                            <div style="display: flex;justify-content: center;">
                                <div class="tbwc" style="padding: 1.15rem 1.4rem;">
                                    <img src="../../../static/img/aimstb.png" style="width: 3.71rem;height: 4.21rem;" />
                                </div>
                            </div>
                            <div class="nevBtnitemtit">AI模拟面试</div>
                            <div class="nevBtnitemms">真实面试场景</div>
                            <div class="nevBtnitemrs">
                                <img class="rsicon" src="../../../static/img/xdrt.png" />{{ ms_num }}
                            </div>
                        </div>
                        <div class="nevBtnitem" @click="shoyerkBtncaozuo('ResumeDiagnosis')">
                            <div style="display: flex;justify-content: center;">
                                <div class="tbwc" style="padding: 1.04rem 1.15rem;">
                                    <img src="../../../static/img/aiyhtb.png" style="width: 4.21rem;height: 4.43rem;"/>
                                </div>
                            </div>
                            <div class="nevBtnitemtit">AI简历诊断</div>
                            <div class="nevBtnitemms">识别优势/劣势</div>
                            <div class="nevBtnitemrs">
                                <img class="rsicon" src="../../../static/img/xdrt.png" />{{ jianli_zd }}
                            </div>
                        </div>
                        <div class="nevBtnitem" @click="shoyerkBtncaozuo('ResumeLibrary')">
                            <div style="display: flex;justify-content: center;">
                                <div class="tbwc" style="padding: 1.29rem 1.325rem;">
                                    <img src="../../../static/img/aizdtb.png" style="width: 3.68rem;height: 3.93rem;"/>
                                </div>
                            </div>
                            <div class="nevBtnitemtit">AI简历优化</div>
                            <div class="nevBtnitemms">分析/评估/优化</div>
                            <div class="nevBtnitemrs">
                                <img class="rsicon" src="../../../static/img/xdrt.png" />{{ jianli_yh }}
                            </div>
                        </div>
                    </div>
                    <div class="nevBtnhang" style="padding-top: 2rem;">
                        <div class="nevBtnitem" @click="shoyerkBtncaozuo('AgentPage')">
                            <div style="display: flex;justify-content: center;">
                                <div class="tbwc" style="padding: 1.04rem 0.935rem;">
                                    <img src="../../../static/img/aizsktb.png" style="width: 4.64rem;height: 4.43rem;" />
                                </div>
                            </div>
                            <div class="nevBtnitemtit">智能体</div>
                            <div class="nevBtnitemms">您的求职面试小秘书</div>
                            <!-- <div class="nevBtnitemrs">
                                <img class="rsicon" src="../../../static/img/xdrt.png" />123
                            </div> -->
                        </div>
                        <div class="nevBtnitem" @click="shoyerkBtncaozuo('SchoolRecruitmentPosition')">
                            <div style="display: flex;justify-content: center;">
                                <div class="tbwc" style="padding: 1.325rem 1.15rem;">
                                    <img src="../../../static/img/aigwtb.png" style="width: 4.21rem;height: 3.86rem;" />
                                </div>
                            </div>
                            <div class="nevBtnitemtit">校招岗位</div>
                            <div class="nevBtnitemms">海量岗位等你来选</div>
                            <!-- <div class="nevBtnitemrs">
                                <img class="rsicon" src="../../../static/img/xdrt.png" />123
                            </div> -->
                        </div>
                        <div class="nevBtnitem" @click="shoyerkBtncaozuo('RechargeCenter')">
                            <div style="display: flex;justify-content: center;">
                                <div class="tbwc" style="padding: 1.22rem 1.22rem;">
                                    <img src="../../../static/img/aicztb.png" style="width: 4.07rem;height: 4.07rem;"/>
                                </div>
                            </div>
                            <div class="nevBtnitemtit">支付中心</div>
                            <div class="nevBtnitemms">超值优惠套餐</div>
                            <!-- <div class="nevBtnitemrs">
                                <img class="rsicon" src="../../../static/img/xdrt.png" />123
                            </div> -->
                        </div>
                    </div>
                </div>

                <!-- <div class="zygnk">
                    <div style="width: 51.07rem;">
                        <el-carousel height="31.79rem" arrow="always">
                            <el-carousel-item v-for="(item, index) in lunbo_img" :key="index">
                                <img class="lbt" :src="item" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="loginmk" v-if="!islogin">
                        <div class="yzmtit">
                            <div class="yzmbt">验证码登录/注册</div>
                            <div class="currzornum">
                                <img class="zrstb" src="../../../static/img/ddrt.png" />
                                {{ zorenshu }}
                            </div>
                        </div>
                        <div class="sjhsr">
                            <span>+86</span>
                            <el-input v-model="mobile" class="sjhinput" style="width: 16.5rem;" placeholder="请输入手机号码" />
                        </div>
                        <div class="sjhsr">
                            <el-input v-model="yzmcode" class="sjhinput" style="width: 16.5rem;" placeholder="请输入验证码" />
                            <el-button type="primary" link class="hqyzmbtn" @click="getyzm" :disabled="isCountingDown">
                                {{ djsText }}
                            </el-button>
                        </div>
                        <el-button type="primary" class="loginBtn" @click="loginBtn">登录/注册</el-button>
                        <div class="zhucexieyi">
                            <el-checkbox v-model="checked" size="large" style="margin-right: 0.5rem;" />
                            已阅读并同意 <el-link type="primary" style="color: #2C60FE;"
                                href="https://ai.jsai100.com/web/show/4.html"
                                target="_blank">《用户服务协议》</el-link>和<el-link type="primary" style="color: #2C60FE;"
                                href="https://ai.jsai100.com/web/show/5.html" target="_blank">《隐私政策》</el-link>
                        </div>
                    </div>

                    <div class="miangnmk" v-if="islogin">
                        <div class="grinfo">
                            <div class="txrs">
                                <div class="xinfo">
                                    <img class="wdtx"
                                        :src="userInfo && userInfo.user_header && userInfo.user_header != '' ? userInfo.user_header : '../../../static/img/wdtx.png'" />
                                    <div>
                                        <div class="mz">{{ userInfo && userInfo.name && userInfo.name != ''
                                            ? userInfo.name : (userInfo && userInfo.nick_name && userInfo.nick_name !=
                                                '' ? userInfo.nick_name : '无名') }}</div>
                                        <div class="zzmc">{{ userInfo && userInfo.store_name && userInfo.store_name !=
                                            '' ? userInfo.store_name : (userInfo && userInfo.a_name && userInfo.a_name
                                                !=
                                                '' ? userInfo.a_name : '暂无组织') }}</div>
                                        <div class="yhid">用户ID：{{ userInfo && userInfo.user_id && userInfo.user_id != ''
                                            ? userInfo.user_id : '0' }}</div>
                                    </div>
                                </div>
                                <div class="currzornum" style="line-height: initial;">
                                    <img class="zrstb" src="../../../static/img/ddrt.png" />
                                    {{ zorenshu }}
                                </div>
                            </div>
                            <div class="dsan" @click="quzhifuzhoxin">
                                <div style="margin-left: 2rem;">余额🅐：{{ userInfo && userInfo.golds ? userInfo.golds :
                                    '0' }}</div>
                                <el-icon style="font-size: 1.36rem;margin-right: 1.5rem;">
                                    <ArrowRight />
                                </el-icon>
                            </div>
                            <div style="height: 2.79rem;"></div>
                        </div>
                        <div class="zyaign">
                            <div class="aign" @click="aimianshi">
                                <img class="aignimg" src="../../../static/img/aimstb.png" />
                                <div class="aigntit">AI模拟面试</div>
                                <div class="aignms">真实面试场景</div>
                                <div class="gnrs">
                                    <img class="gnrsimg" src="../../../static/img/xdrt.png" />
                                    {{ ms_num }}
                                </div>
                            </div>
                            <div class="aign" style="margin: 0 0.93rem;" @click="aijianlizheduan">
                                <img class="aignimg" src="../../../static/img/aizdtb.png" />
                                <div class="aigntit">AI简历诊断</div>
                                <div class="aignms">识别优势/劣势</div>
                                <div class="gnrs">
                                    <img class="gnrsimg" src="../../../static/img/xdrt.png" />
                                    {{ jianli_zd }}
                                </div>
                            </div>
                            <div class="aign" @click="aijianliyouhua">
                                <img class="aignimg" src="../../../static/img/aiyhtb.png" />
                                <div class="aigntit">AI简历优化</div>
                                <div class="aignms">分析/评估/优化</div>
                                <div class="gnrs">
                                    <img class="gnrsimg" src="../../../static/img/xdrt.png" />
                                    {{ jianli_yh }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <el-dialog v-model="loginVisible" width="31rem" align-center :show-close="false" @close="handleDialogClose">
                    <div class="yzmtit">
                        <div class="yzmbt">验证码登录/注册</div>
                        <!-- <div class="currzornum">
                            <img class="zrstb" src="../../../static/img/lsddrt.png" />
                            {{ zorenshu }}
                        </div> -->
                    </div>
                    <div class="sjhsr">
                        <span>+86</span>
                        <el-input v-model="mobile" class="sjhinput" style="width: 16.5rem;" placeholder="请输入手机号码" />
                    </div>
                    <div class="sjhsr">
                        <el-input v-model="yzmcode" class="sjhinput" style="width: 16.5rem;" placeholder="请输入验证码" />
                        <el-button type="primary" link class="hqyzmbtn" @click="getyzm" :disabled="isCountingDown">
                            {{ djsText }}
                        </el-button>
                    </div>
                    <el-button type="primary" class="loginBtn" @click="loginBtn">登录/注册</el-button>
                    <div class="zhucexieyi">
                        <el-checkbox v-model="checked" size="large" style="margin-right: 0.5rem;" />
                        已阅读并同意 <el-link type="primary" style="color: #2C60FE;"
                            href="https://ai.jsai100.com/web/show/4.html" target="_blank">《用户服务协议》</el-link>和<el-link
                            type="primary" style="color: #2C60FE;" href="https://ai.jsai100.com/web/show/5.html"
                            target="_blank">《隐私政策》</el-link>
                    </div>
                </el-dialog>

                <div class="zxxzgwtit">
                    <div class="zxgw">
                        最新校招岗位
                        <div class="gwtitxhx"></div>
                    </div>
                    <div class="ckgd" @click="ganweiyemian">查看更多<el-icon>
                            <DArrowRight />
                        </el-icon></div>
                </div>
                <div class="gwitem" v-for="(item, index) in job" :key="index" @click="ganweigw(item.off_url)">
                    <div class="zbnr">
                        <div :class="['xzbs', { 'sxbs': item.position_type === '2' }]">{{ item.position_type === "1" ?
                            "校招"
                            : "实习" }}</div>
                        <div>
                            <div class="gsmz">{{ item.enterprise_name }}<span class="qyxz">({{ item.enterprise_nature
                                    }})</span></div>
                            <div class="gwtxt">行业：{{ item.industry }}</div>
                            <div class="gwtxt">职位：{{ item.position }}</div>
                            <div class="dz">
                                <img class="dwtb" src="../../../static/img/dwtb.png" />
                                {{ item.work_location }}
                            </div>
                        </div>
                    </div>
                    <div class="ybnr">
                        <div class="rq">{{ jssysj(item.ctime) }}</div>
                        <el-icon class="rq" style="font-size: 1.4rem;margin: 0 1.36rem 0 9rem;">
                            <ArrowRight />
                        </el-icon>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script setup>
import { ref, defineExpose, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';

const store = useStore();

const mobile = ref('');
const yzmcode = ref('');
const checked = ref(false);

let islogin = computed(() => store.getters.getislogin);

if (localStorage.getItem('islogin') === 'true') {
    // islogin.value = true;
    store.dispatch('asyncLogin', true);
}

const getyzm = () => {
    if (mobile.value != '') {
        send_message();
    }
}

const loginBtn = () => {
    if (mobile.value === '') {
        ElMessage({
            message: '请先输入手机号',
            type: 'warning',
        })
    } else if (yzmcode.value === '') {
        ElMessage({
            message: '请先输入验证码',
            type: 'warning',
        })
    } else if (checked.value === false) {
        ElMessage({
            message: '请阅读并同意《用户服务协议》和《隐私政策》',
            type: 'warning',
        })
    }
    else {
        login_phone_code();
    }
}

const router = useRouter();

const loginVisible = computed(() => store.getters.getLoginTankuang);
const shoyerkBtncaozuo = (yemianName) => {
    if (!islogin.value) {
        ElMessage({
            message: '请先登录',
            type: 'warning',
        })
        store.dispatch('asyncUpdateLoginTankuang', true);
        return;
    }
    
    store.commit('updateSosuoInput', "");
    // store.dispatch('asyncUpdateSosuoInput', " ");
    router.push({ name: yemianName });
}

// const quzhifuzhoxin = () => {
//     router.push({ name: 'RechargeCenter' });
// }
// const aimianshi = () => {
//     router.push({ name: 'AIinterview' });
// }
// const aijianlizheduan = () => {
//     router.push({ name: 'ResumeDiagnosis' });
// }
// const aijianliyouhua = () => {
//     router.push({ name: 'ResumeLibrary' });
// }

const handleDialogClose = () => {
//   console.log('对话框已关闭');
  store.dispatch('asyncUpdateLoginTankuang', false);
};

const ganweiyemian = () => {
    if (islogin.value) {
        router.push({ name: 'SchoolRecruitmentPosition' });
    } else {
        ElMessage({
            message: '请先登录',
            type: 'warning',
        })
    }
}

const ganweigw = (gwurl) => {
    console.log(gwurl);
    get_job_count();
    window.open(gwurl);
}

const jssysj = function (serverTimestamp) {
    let dateObj = new Date(serverTimestamp * 1000);
    // 获取年、月、日
    let year = dateObj.getFullYear();
    let month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    let day = ('0' + dateObj.getDate()).slice(-2);
    // 格式化日期
    return `${year}年${month}月${day}日`;
};

const lunbo_img = ref([]);
const job = ref([]);

//【获取首页数据——公共】
const get_index_data_public = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=response&a=get_index_data_public');
        console.log(response.data); // 处理返回的数据
        lunbo_img.value = response.data.data.lunbo_img;
        job.value = response.data.data.job;
    } catch (error) {
        console.error('There was an error!', error);
    }
};
get_index_data_public();


const zorenshu = ref(0);
const ms_num = ref(0);
const jianli_zd = ref(0);
const jianli_yh = ref(0);
//【获取数据信息】
const get_data_count = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=response&a=get_data_count');
        console.log(response.data); // 处理返回的数据
        zorenshu.value = response.data.data.online_num;
        ms_num.value = response.data.data.ms_num;
        jianli_zd.value = response.data.data.jianli_zd;
        jianli_yh.value = response.data.data.jianli_yh;
    } catch (error) {
        console.error('There was an error!', error);
    }
};
get_data_count();

const jiangeShijian = ref(0);
const isCountingDown = ref(false);
const djsText = ref('获取验证码');
//【发送验证码】
const send_message = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=user&a=send_message', {
            "mobile": mobile.value,
            "type": 1,
        });
        console.log(response.data); // 处理返回的数据
        jiangeShijian.value = response.data.data.jiange;
        isCountingDown.value = true;
        const timer = setInterval(() => {
            jiangeShijian.value--;
            if (jiangeShijian.value <= 0) {
                clearInterval(timer);
                isCountingDown.value = false;
                djsText.value = '获取验证码';
            } else {
                djsText.value = `${jiangeShijian.value}秒`;
            }
        }, 1000);

    } catch (error) {
        console.error('There was an error!', error);
    }
};


//【验证码登录】
const login_phone_code = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=user&a=login_phone_code', {
            "mobile": mobile.value,
            "code": yzmcode.value,
        });
        console.log(response.data); // 处理返回的数据
        if (response.data.status_code === 'success') {
            // islogin.value = true;
            localStorage.setItem('key', response.data.data.key);
            localStorage.setItem('user_id', response.data.data.user_id);
            localStorage.setItem('islogin', 'true');
            store.dispatch('asyncLogin', true);
            store.dispatch('asyncUpdateLoginTankuang', false);
        }
    } catch (error) {
        console.error('There was an error!', error);
    }
};


const userInfo = ref(null);
//获取个人信息
const get_user_info = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=response&a=get_user_info', {
            "user_id": localStorage.getItem('user_id'),
            "key": localStorage.getItem('key'),
        });
        console.log(response.data); // 处理返回的数据
        if (response.data.status_code === 'success') {
            store.dispatch('asyncUpdateUserInfo', response.data.data);
            userInfo.value = response.data.data;
        } else if (response.data.status_code === "pubError2" || response.data.status_code === "pubError3") {//验证失败,请重新登陆
            store.dispatch('asyncLogin', false);
            localStorage.removeItem('islogin');
            localStorage.removeItem('key');
            localStorage.removeItem('user_id');
        }
    } catch (error) {
        console.error('There was an error!', error);
    }
};


//【添加校招岗位点击计数】 
const get_job_count = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=user&a=get_job_count', {
            "user_id": localStorage.getItem('user_id'),
            "key": localStorage.getItem('key')
        });
        console.log(response.data); // 处理返回的数据
        if (response.data.status_code === 'success') {
            console.log(response.data);
        } else if (response.data.status_code === "pubError2" || response.data.status_code === "pubError3") {//验证失败,请重新登陆
            store.dispatch('asyncLogin', false);
            localStorage.removeItem('islogin');
            localStorage.removeItem('key');
            localStorage.removeItem('user_id');
            router.push({ name: 'HomePage' });
        }
    } catch (error) {
        console.error('There was an error!', error);
    }
};



onMounted(() => {
    if (islogin.value) {
        console.log(islogin.value, '111111111111111111');
        get_user_info();
    }
});

watch(islogin, (newVal) => {
    if (newVal) {
        console.log(islogin.value, newVal, '222222222222');
        get_user_info();
    } else {
        console.log(islogin.value, newVal, '33333333333');

    }

});


defineExpose({
    name: 'HomePage'
});
</script>


<style scoped>
.yemianzhuti {
    margin: 2.07rem 0 6.21rem;
}

.nevBtnhang{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nevBtnitem{
    width: 26rem;
height: 17.14rem;
background: #FFFFFF;
box-shadow: 0rem 0rem 2rem 0rem rgba(0,0,0,0.07);
border-radius: 0.57rem;
cursor: pointer;
transition: all 0.3s ease;
}
.nevBtnitem:hover {
    transform: perspective(1000px) rotateX(5deg) rotateY(-5deg) translateY(-5px);
    /* transform: perspective(1000px) rotateX(180deg) rotateX(180deg) rotateY(-10deg); */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
.tbwc{
    /* width: 3.71rem;
    height: 4.21rem; */
    /* padding: 1.22rem 1.22rem; */
    border-radius: 50%;
    background: #F4F8FD;
    margin-top: 2.14rem;
}
.nevBtnitemtit{
    font-family: PingFang SC;
font-weight: bold;
font-size: 2.14rem;
color: #1A1A1A;
text-align: center;
margin-top: 1rem;
}
.nevBtnitemms{
    font-family: PingFang SC;
font-weight: 500;
font-size: 1.14rem;
color: #999999;
text-align: center;
margin: 0.5rem 1.21rem 0;
}
.nevBtnitemrs{
    font-family: PingFang SC;
font-weight: 500;
font-size: 1.25rem;
color: #808080;
display: flex;
    align-items: center;
    margin-left: 1.21rem;
}
.rsicon{
    width: 1.29rem;
    height: 1rem;
    margin-right: 0.3rem;
}


.zygnk {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lbt {
    width: 51.07rem;
    height: 31.79rem;
    border-radius: 0.57rem;
}


.loginmk {
    height: 31.79rem;
    background-color: #fff;
    border-radius: 0.57rem;
    margin-left: 2rem;
}

.yzmtit {
    padding: 1rem 2rem 1rem;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    /* margin-top: 2rem; */
}

.yzmbt {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.29rem;
    color: #4D4D4D;
    line-height: 3.07rem;
}

.currzornum {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.21rem;
    color: #4D4D4D;
    line-height: 3.07rem;
}

.zrstb {
    width: 1.64rem;
    height: 1.07rem;
}

.sjhsr {
    width: 20rem;
    padding: 0 0.57rem;
    margin: 2rem 3rem 0;
    border-bottom: 0.07rem solid #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #333333;
    height: 3.07rem;
    line-height: 3.07rem;
}

.sjhinput {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #333333;
    line-height: 3.07rem;
}

:deep(.el-input__wrapper) {
    box-shadow: none;
}

:deep(.el-input__wrapper:hover) {
    box-shadow: none;
}

:deep(.el-input__wrapper:active) {
    box-shadow: none;
}

:deep(.el-input__wrapper:focus) {
    box-shadow: none;
}

.hqyzmbtn {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #2C60FE;
    line-height: 3.07rem;
}

.loginBtn {
    width: 18rem;
    height: 3.36rem;
    background: #2C60FE;
    border-radius: 0.36rem;
    margin: 4rem 4.57rem 0;

    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.29rem;
    color: #FFFFFF;
    line-height: 3.36rem;
}

.zhucexieyi {
    margin: 3rem 3rem 0;
    display: flex;
    align-items: center;

    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #B3B3B3;
}

.miangnmk {
    margin-left: 2rem;
}

.grinfo {
    background-color: #fff;
    margin-bottom: 1.21rem;
}

.txrs {
    padding: 1.79rem 2rem 1.71rem 1.57rem;
    /* width: 23.57rem; */
    display: flex;
    justify-content: space-between;
}

.wdtx {
    width: 6.66rem;
    height: 6.66rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.xinfo {
    display: flex;
}

.mz {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #333333;
    /* line-height: 3.07rem; */
}

.zzmc {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.07rem;
    color: #4D4D4D;
    /* line-height: 3.07rem; */
    background: #FFE6CA;
    border-radius: 0.75rem;
    padding: 0.29rem 0.57rem;
    margin: 0.5rem 0;
}

.yhid {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.07rem;
    color: #4D4D4D;
    /* line-height: 3.07rem; */
}

.dsan {
    width: 24.72rem;
    height: 4.7rem;
    background-image: url("../../../static/img/dsan.png");
    background-size: 100% 100%;
    margin: 0 2.07rem;

    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #FFFFFF;
    line-height: 4.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
}

.zyaign {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aign {
    width: 9rem;
    /* height: 13.93rem; */
    background: #FFFFFF;
    border-radius: 0.57rem;
    text-align: center;
    cursor: pointer;
}

.aignimg {
    width: 3.79rem;
    height: 3.79rem;
    margin: 1.29rem 0 0;
}

.aigntit {
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 1.43rem;
    color: #1A1A1A;
    margin: 1rem 0 0.4rem 0;
}

.aignms {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 1.14rem;
    color: #999999;
}

.gnrs {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 1.25rem;
    color: #808080;
    display: flex;
    align-items: center;
    margin: 0.7rem 0 0.3rem 1.07rem;
}

.gnrsimg {
    width: 1.29rem;
    height: 1rem;
    margin-right: 0.29rem;
}


.zxxzgwtit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2.8rem 0 0.93rem;
}

.zxgw {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.29rem;
    color: #4D4D4D;
    line-height: 3.07rem;
    display: grid;
    place-items: center;
}

.gwtitxhx {
    width: 1.71rem;
    height: 0.29rem;
    background: #2C60FE;
    border-radius: 0.14rem;
}

.ckgd {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.07rem;
    color: #2C60FE;
    line-height: 3.07rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.gwitem {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 0.57rem;
    margin-bottom: 1.29rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.gwitem:hover {
    transform: perspective(1000px) rotateX(2.5deg) rotateX(2.5deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.zbnr {
    display: flex;
}

.xzbs {
    width: 2.71rem;
    height: 1.43rem;
    background: #FF5733;
    border-radius: 0rem 0.71rem 0.71rem 0rem;

    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #FFFFFF;
    line-height: 1.43rem;
    text-align: center;
    margin-right: 1rem;
    margin-top: 0.6rem;
}

.sxbs {
    background: #2C60FE;
}

.gsmz {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.29rem;
    color: #333333;
    line-height: 3.07rem;
}

.qyxz {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #808080;
}

.gwtxt {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #808080;
    /* line-height: 3.07rem; */
}

.dz {
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #808080;
    line-height: 3.07rem;
}

.dwtb {
    width: 1.14rem;
    height: 1.07rem;
    margin-right: 0.64rem;
}

.ybnr {
    display: flex;
    align-items: center;
}

.rq {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #B3B3B3;
}
</style>
import { createStore } from 'vuex';
const store = createStore({
    state: {
        // 定义全局状态
        globalData: {
            userInfo: {},
            sosuoInput: '',
            zhishikuUrl: '',
            islogin: false, // 初始登录状态
            yhjlId:'',
            myymIndex: 1,
            loginTankuang: false, // 登录弹框
        }
    },
    mutations: {
        // 定义修改全局状态的方法
        updateUserInfo(state, newUserInfo) {
            state.globalData.userInfo = newUserInfo;
        },
        updateSosuoInput(state, newSosuoInput) {
            state.globalData.sosuoInput = newSosuoInput;
        },
        updatezhishikuUrl(state, newUrl) {
            state.globalData.zhishikuUrl = newUrl;
        },
        setLoggedIn(state, isLoggedIn) {
            state.globalData.islogin = isLoggedIn;
        },
        updateYhjlId(state, newjlId) {
            state.globalData.yhjlId = newjlId;
        },
        updatemyymIndex(state, newmyymIndex) {
            state.globalData.myymIndex = newmyymIndex;
        },
        updateLoginTankuang(state, newLoginTankuang) {
            state.globalData.loginTankuang = newLoginTankuang;
        },
    },
    actions: {
        // 定义异步操作，可以在这里调用 mutations
        asyncUpdateUserInfo({ commit }, newUserInfo) {
            // 可以在这里进行异步操作，比如调用API获取最新用户信息
            commit('updateUserInfo', newUserInfo);
        },
        asyncUpdateSosuoInput({ commit }, newSosuoInput) {
            // 可以在这里进行异步操作，比如调用API获取最新用户信息
            commit('updateSosuoInput', newSosuoInput);
        },
        // asyncUpdateThemeColor({ commit }, newColor) {
        //     // 假设这里有一些异步逻辑，比如等待动画完成后更新颜色
        //     setTimeout(() => {
        //         commit('updateThemeColor', newColor);
        //     }, 1000);
        // },
        asyncLogin({ commit }, isLoggedIn) {
            commit('setLoggedIn', isLoggedIn);
        },
        asyncUpdateYhjlId({ commit }, newjlId) {
            commit('updateYhjlId', newjlId);
        },
        asyncUpdatemyymIndex({ commit }, newmyymIndex) {
            commit('updatemyymIndex', newmyymIndex);
        },
        asyncUpdateLoginTankuang({ commit }, newLoginTankuang) {
            commit('updateLoginTankuang', newLoginTankuang);
        },
    },
    getters: {
        // 定义获取全局状态的方法
        getGlobalData(state) {
            return state.globalData;
        },
        getUserInfo(state) {
            return state.globalData.userInfo;
        },
        getSosuoInput(state) {
            return state.globalData.sosuoInput;
        },
        getzhishikuUrl(state) {
            return state.globalData.zhishikuUrl;
        },
        getislogin(state) {
            return state.globalData.islogin;
        },
        getYhjlId(state) {
            return state.globalData.yhjlId;
        },
        getMyymIndex(state) {
            return state.globalData.myymIndex;
        },
        getLoginTankuang(state) {
            return state.globalData.loginTankuang;
        }
    }
});
export default store;
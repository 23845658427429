<template>

    <div class="yetozujian">
        <el-affix :offset="0">
            <div class="yetop">
                <el-row justify="center">
                    <el-col :span="18" :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                        <div class="zhojiantop">
                            <div class="yetoptxt" style="display: flex; align-items: center;cursor: pointer;"
                                @click="shoyebtn">
                                <img class="sytb" src="../../static/img/sytb.png" />
                                首页
                            </div>
                            <div style="display: flex;align-items: center;">
                                <div class="currzornum" style="line-height: initial;margin-right: 4rem;">
                                    <img class="zrstb" src="../../static/img/ddrt.png" />
                                    {{ zorenshu }}
                                </div>
                                <div class="yetoptxt dltbwz" v-if="!isLoggedIn" @click="dianjilogin">
                                    <img class="dlxr" src="../../static/img/dlxr.png" />
                                    登录/注册
                                </div>
                                <div class="yetoptxt dltbwz" v-if="isLoggedIn">
                                    <span style="margin-right: 2rem;">欢迎回来</span>
                                    <el-dropdown trigger="click" @command="handleCommand">
                                        <span class="yetoptxt dltbwz">
                                            <img class="dlxr" src="../../static/img/dlxr.png" />
                                            <span>{{ userInfo && userInfo.name && userInfo.name != ''
                                                ? userInfo.name : (userInfo && userInfo.nick_name && userInfo.nick_name
                                                    !=
                                                    '' ? userInfo.nick_name : '个人中心') }}</span>
                                            <el-icon style="margin-left: 0.3rem;">
                                                <ArrowDown />
                                            </el-icon>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item command="PersonalInfo">个人信息</el-dropdown-item>
                                                <el-dropdown-item divided
                                                    command="InterviewReport">面试报告</el-dropdown-item>
                                                <el-dropdown-item divided
                                                    command="ResumeLibrary">我的简历库</el-dropdown-item>
                                                <el-dropdown-item divided
                                                    command="ResumeDiagnosticReport">简历诊断报告</el-dropdown-item>
                                                <el-dropdown-item divided
                                                    command="RechargeCenter">支付中心</el-dropdown-item>
                                                <el-dropdown-item divided command="feedBack">意见反馈</el-dropdown-item>
                                                <el-dropdown-item divided command="HomePage">退出登录</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </div>

                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-affix>
        <el-row justify="center">
            <el-col :span="18" :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                <div class="yetonr">
                    <img class="aizntb" src="../../static/img/aizntb.png" />
                    <div class="sosuo">
                        <el-input v-model="sosuoinput" style="width: 35.43rem;height: 3.14rem;" placeholder="请输入搜索内容" />
                        <el-button type="primary" size="large" class="sosuobtn" @click="sosuoBtn">搜 索</el-button>
                    </div>

                    <div class="xinfo" v-if="isLoggedIn">
                        <img class="wdtx"
                            :src="userInfo && userInfo.user_header && userInfo.user_header != '' ? userInfo.user_header : '../../../static/img/wdtx.png'" />
                        <div>
                            <div class="gzxcxz">{{ userInfo && userInfo.name && userInfo.name != ''
                                ? userInfo.name : (userInfo && userInfo.nick_name && userInfo.nick_name !=
                                    '' ? userInfo.nick_name : '无名') }}</div>
                            <div class="zzmc">{{ userInfo && userInfo.store_name && userInfo.store_name !=
                                '' ? userInfo.store_name : (userInfo && userInfo.a_name && userInfo.a_name
                                    !=
                                    '' ? userInfo.a_name : '暂无组织') }}</div>
                            <div class="yhid">用户ID：{{ userInfo && userInfo.user_id && userInfo.user_id != ''
                                ? userInfo.user_id : '0' }}</div>
                            <div class="yhid">余额🅐：{{ userInfo && userInfo.golds ? userInfo.golds :
                                '0' }}</div>
                        </div>
                    </div>

                    <div class="gzxcxan" v-if="!isLoggedIn">
                        <img class="xcxtb" src="../../static/img/msgxcx.jpg" />
                        <div>
                            <div class="gzxcxz">关注微信小程序</div>
                            <div class="gzxcxsm">了解最新的动态</div>
                        </div>
                    </div>
                </div>

                <!-- </el-affix> -->
            </el-col>
        </el-row>
    </div>

</template>

<script setup>
import { ref, defineExpose, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useStore } from 'vuex';
// import { ElMessage } from 'element-plus';

const router = useRouter();
const store = useStore();

const sosuoinput = ref('');

const isLoggedIn = computed(() => store.getters.getislogin);
const userInfo = computed(() => store.getters.getUserInfo);
const sosuoinputnr = computed(() => store.getters.getSosuoInput);
sosuoinput.value = sosuoinputnr.value;

const sosuoBtn = () => {
    store.dispatch('asyncUpdateSosuoInput', sosuoinput.value);
}
// sosuoinput.value = '';
//     store.dispatch('asyncUpdateSosuoInput', sosuoinput.value);

const shoyebtn = () => {
    router.push({ name: 'HomePage' });
}

if (localStorage.getItem('islogin') === 'true') {
    store.dispatch('asyncLogin', true);
}

const dianjilogin = () => {
    store.dispatch('asyncUpdateLoginTankuang', true);
}


const handleCommand = (command) => {
    switch (command) {
        case 'PersonalInfo':
            store.dispatch('asyncUpdatemyymIndex', 1);
            break;
        case 'InterviewReport':
            store.dispatch('asyncUpdatemyymIndex', 2);
            break;
        case 'ResumeLibrary':
            store.dispatch('asyncUpdatemyymIndex', 3);
            break;
        case 'ResumeDiagnosticReport':
            store.dispatch('asyncUpdatemyymIndex', 4);
            break;
        case 'RechargeCenter':
            store.dispatch('asyncUpdatemyymIndex', 5);
            break;
        case 'feedBack':
            store.dispatch('asyncUpdatemyymIndex', 6);
            break;
        case 'HomePage'://退出登录操作
            store.dispatch('asyncLogin', false);
            localStorage.removeItem('islogin');
            localStorage.removeItem('key');
            localStorage.removeItem('user_id');
            break;
    }
    router.push({ name: command });
}

const zorenshu = ref(0);
//【获取数据信息】
const get_data_count = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=response&a=get_data_count');
        console.log(response.data); // 处理返回的数据
        zorenshu.value = response.data.data.online_num;
    } catch (error) {
        console.error('There was an error!', error);
    }
};
get_data_count();


const know_robot = ref([]);
//【获取首页数据——公共】
const get_index_data_public = async () => {
    try {
        const response = await axios.post('https://ai.jsai100.com/web/index.php?m=response&a=get_index_data_public');
        console.log(response.data); // 处理返回的数据
        know_robot.value = response.data.data.know_robot;

    } catch (error) {
        console.error('There was an error!', error);
    }
};
get_index_data_public();



onMounted(() => {
    if (sosuoinputnr.value) {
        sosuoinput.value = sosuoinputnr.value;
        console.log(sosuoinputnr.value, 'aaaaaaaaaaaaaaaaaa111111111111111111');
        // loadUserData();
    }
});

watch(sosuoinputnr, (newVal) => {
    if (newVal) {
        sosuoinput.value = sosuoinputnr.value;
        console.log(sosuoinputnr.value, newVal, 'ssssssss');
    } else {
        console.log(sosuoinputnr.value, newVal, 'aaaaaaaaaaaaaaaaaa33333333333');

    }
});

defineExpose({
    name: 'PageHeading'
});
</script>


<style scoped>
.yetozujian {
    width: 100%;
    /* height: 19rem; */
    background: #FFFFFF;
}

.yetop {
    width: 100%;
    height: 2.86rem;
    background: #2C60FE;
}

.zhojiantop {
    height: 2.86rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.yetoptxt {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #FFFFFF;
    /* line-height: 3.07rem; */
}

.sytb {
    width: 1.29rem;
    height: 1.29rem;
    margin-right: 0.3rem;
}

.currzornum {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.21rem;
    color: #fff;
    /* line-height: 3.07rem; */
}

.zrstb {
    width: 1.64rem;
    height: 1.07rem;
}

.dltbwz {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dlxr {
    width: 1.07rem;
    height: 1.21rem;
    margin-right: 0.5rem;
}

.yetonr {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aizntb {
    width: 14.29rem;
    height: 5.79rem;
}

.sosuo {
    display: flex;
    align-items: center;
}

.sosuobtn {
    width: 6.71rem;
    height: 3.14rem;
    background: #2C60FE;
    font-family: Microsoft YaHei;
    font-weight: 400;
    /* font-size: 1.21rem; */
    margin-left: -5px;
    z-index: 2;
}

.xcxtb {
    width: 8rem;
    height: 8rem;
}

.gzxcxan {
    /* display: flex;
    align-items: center; */
    cursor: pointer;
    /* flex-direction: row; */
    text-align: center;
}

.gzxcxz {
    font-family: Microsoft YaHei;
    font-weight: bold;
    font-size: 1.07rem;
    color: #333333;
    white-space: nowrap;
}

.gzxcxsm {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #999999;
    white-space: nowrap;
}

.el-menu-demo {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.29rem;
    color: #333333;
    height: 3.07rem;
    line-height: 3.07rem;
}

.el-menu--horizontal.el-menu {
    border: none;
}



.xinfo {
    display: flex;
    align-items: center;
}

.wdtx {
    width: 6.66rem;
    height: 6.66rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.mz {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.14rem;
    color: #333333;
    /* line-height: 3.07rem; */
}

.zzmc {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 0.86rem;
    color: #4D4D4D;
    /* line-height: 3.07rem; */
    background: #FFE6CA;
    border-radius: 0.75rem;
    padding: 0.2rem 0.57rem;
    margin: 0.5rem 0;
}

.yhid {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 0.86rem;
    color: #4D4D4D;
    /* line-height: 3.07rem; */
}
</style>
<template>
  <div>
  <PageHeading />
  <router-view />
  <PageFooter />
</div>
</template>

<script>
import PageHeading from './components/PageHeading.vue';
import PageFooter from './components/PageFooter.vue';

document.title = '超级AI面试官';

export default {
  name: 'App',
  components: {
    PageHeading,
    PageFooter
  }
}
</script>

<style>
html, body, #app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */

    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    background-color: #F4F8FD;
    font-size: 14px;
    /* overflow: hidden; */
    position: relative;    
}

/* 自定义滚动条样式 */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c4c3c3;
  border-radius: 20px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>

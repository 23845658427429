import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/home/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/AIinterview',
    name: 'AIinterview',
    component: () => import('../views/home/AIinterview.vue')
  },
  {
    path: '/AgentPage',
    name: 'AgentPage',
    component: () => import('../views/agent/AgentPage.vue')
  },
  {
    path: '/AgentRobot',
    name: 'AgentRobot',
    component: () => import('../views/agent/AgentRobot.vue')
  },
  {
    path: '/SchoolRecruitmentPosition',
    name: 'SchoolRecruitmentPosition',
    component: () => import('../views/gangwei/SchoolRecruitmentPosition.vue')
  },
  {
    path: '/ResumeLibrary',
    name: 'ResumeLibrary',
    component: () => import('../views/home/ResumeLibrary.vue')
  },
  {
    path: '/ResumeDiagnosis',
    name: 'ResumeDiagnosis',
    component: () => import('../views/home/ResumeDiagnosis.vue')
  },
  {
    path: '/CreateResume',
    name: 'CreateResume',
    component: () => import('../views/home/CreateResume.vue')
  },
  {
    path: '/ResumeOptimization',
    name: 'ResumeOptimization',
    component: () => import('../views/home/ResumeOptimization.vue')
  },
  {
    path: '/MyPage',
    name: 'MyPage',
    component: () => import('../views/my/MyPage.vue'),
    children: [
      {
        path: '/PersonalInfo',
        name: 'PersonalInfo',
        component: () => import('../views/my/PersonalInfo.vue')
      },
      {
        path: '/InterviewReport',
        name: 'InterviewReport',
        component: () => import('../views/my/InterviewReport.vue')
      },
      {
        path: '/RechargeCenter',
        name: 'RechargeCenter',
        component: () => import('../views/my/RechargeCenter.vue')
      },
      {
        path: '/ResumeDiagnosticReport',
        name: 'ResumeDiagnosticReport',
        component: () => import('../views/my/ResumeDiagnosticReport.vue')
      },
      {
        path: '/feedBack',
        name: 'feedBack',
        component: () => import('../views/my/feedBack.vue')
      },
      {
        path: '/RechargeRecord',
        name: 'RechargeRecord',
        component: () => import('../views/my/RechargeRecord.vue')
      },
      {
        path: '/BalanceRecord',
        name: 'BalanceRecord',
        component: () => import('../views/my/BalanceRecord.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})

export default router
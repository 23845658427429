<template>
    <div class="yejiao">
        <el-row justify="center">
            <el-col :span="18" :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                <div class="yejiaowz">
                    <div style="text-align: center;">
                        <img class="xcxtb" src="../../static/img/wxgzh.jpg" />
                        <div>
                            <div class="gzxcxz">关注微信公众号</div>
                        </div>
                    </div>
                   <div>© 2013-2025 谨世智能 版权所有 沪ICP备2023040758号-1</div>
                   <div style="text-align: center;">
                        <img class="xcxtb" src="../../static/img/msgxcx.jpg" />
                        <div>
                            <div class="gzxcxz">关注微信小程序</div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import { defineExpose } from 'vue';

defineExpose({
    name: 'PageFooter'
});
</script>


<style scoped>
.yejiao {
    width: 100%;
    /* height: 7.86rem; */
    background: #383838;
    /* display: grid;
    place-items: center; */
}

.yejiaowz {
    width: 100%;
    /* height: 7.86rem; */
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1rem;
    color: #FFFFFF;
    line-height: 1.71rem;
    /* text-align: center; */
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
    padding: 2rem 0;
}
.xcxtb {
    width: 8rem;
    height: 8rem;
}
</style>